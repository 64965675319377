import { useEffect, useState } from "react";
import PageComponent from "../page-component";
import { useGlobalState } from "../contexts/global-contexts";

const Cancellation = () => {
  const { state } = useGlobalState();
  const { language } = state;
  const [cancellationText, setCancellationText] = useState({
    cancellation: { title: "", description: "" },
    interpretation: { title: "", description: "" },
    translation: { title: "", description: "" },
    training: { title: "", description: "" },
    final: { title: "", description: "" },
  });

  useEffect(() => {
    switch (language) {
      case "English":
        setCancellationText({
          cancellation: {
            title: "CANCELLATION POLICY",
            description:
              "The services that Shaoyao Consulting provides to its clients are performed by a select group of professionals whose availability is limited. For this reason, the dates for contracting our services are reserved exclusively through the payment of a deposit or by signing a contract, as applicable. We also inform all our clients that any cancellation or change will generate a charge according to the following scenarios.",
          },
          interpretation: {
            title: "INTERPRETATION SERVICES",
            description:
              "When the client has reserved the dates for an interpretation service in any of its modalities, in case of cancellation due to causes not attributable to Shaoyao Consulting at any time after the advance payment or signed contract and up to 72 hours before the start of the event, the client will pay a penalty of 50% of the total amount quoted. If the cancellation occurs within 72 hours prior to the start of the event, the penalty will be 100% of the total amount quoted. \n\nLikewise, date or time changes notified to Shaoyao Consulting at any time after the deposit is paid or the contract is signed will be subject to consideration and may be accepted or rejected depending on the availability of interpreters. In the event that the changes are not accepted by Shaoyao Consulting, the service will be provided on the dates and times originally reserved, and the client will have to accept the original dates or proceed with the cancellation of the service according to the conditions of the previous paragraph.",
          },
          translation: {
            title: "TRANSLATION SERVICES",
            description:
              "When the client has accepted a translation service in any of its modalities, or any other service that consists of the delivery of written deliverables, in the event that the service is cancelled for reasons not attributable to Shaoyao Consulting at any time after the advance payment or contract is signed and up to 5 business days before the agreed delivery date, the client will pay a penalty of 50% of the total amount quoted. In case the cancellation occurs within 5 working days prior to the agreed delivery date, the penalty will be 100% of the total amount quoted.\n\nLikewise, delivery date changes notified to Shaoyao Consulting at any time after the retainer is paid or the contract is signed will be subject to consideration and may be accepted or rejected depending on the availability of translators and/or consultants. In the event that the changes are not accepted by Shaoyao Consulting, the deliverables will be sent on the originally agreed date, and the client will have to accept the original date or proceed with the cancellation of the service according to the conditions of the previous paragraph.",
          },
          training: {
            title: "TRAINING SERVICES",
            description:
              "When the client has reserved the dates for a training service in any of its modalities, including courses, workshops, conferences, webinars, and any other service that requires the face-to-face or virtual assistance of one or more speakers or instructors, in the event that the same is cancelled for reasons not attributable to Shaoyao Consulting at any time after the advance payment or signed contract and up to 72 hours before the start of the event, the client will pay a penalty of 50% of the total amount quoted. If the cancellation occurs within 72 hours prior to the start of the event, the penalty will be 100% of the total amount quoted.\n\nLikewise, date or time changes notified to Shaoyao Consulting at any time after the advance payment or signed contract will be subject to consideration and may be accepted or rejected depending on the availability of speakers and/or instructors. In the event that the changes are not accepted by Shaoyao Consulting, the service will be provided on the dates and times originally reserved, and the client will have to accept the original dates or proceed with the cancellation of the service according to the conditions of the previous paragraph.",
          },
          final: {
            title: "FINAL CONSIDERATIONS",
            description:
              "In case of cancellation of any of the services mentioned in this policy for causes attributable to Shaoyao Consulting, Shaoyao Consuting will return to the client the full amount that has been transferred to the client as an advance payment (if any), not incurring any other penalty and being free of any additional liability to the client.\n\nIn no case may the client personally choose the interpreters, translators, instructors, consultants or other professionals assigned to their project, this being the exclusive right of Shaoyao Consulting. Therefore, the identity of the person or persons performing the service or services accepted by the client cannot at any time and under any circumstances be considered a valid reason for cancellation attributable to Shaoyao Consulting, but will fall exclusively within the responsibility of the client to cancel a service or services for that reason.\n\nBy accepting any of the services provided by Shaoyao Consulting through the payment of a retainer and/or the signing of a contract, as applicable, you agree to be bound by the terms of this cancellation policy.",
          },
        });
        break;
      case "中文":
        setCancellationText({
          cancellation: {
            title: "取消政策",
            description:
              "Shaoyao Consulting为客户提供的服务是由精选的专业人员执行的，他们的可用性是有限的。因此，只有通过支付定金或签订合同（视情况而定），才能预订我们的服务日期。我们还告知所有客户，任何取消或改变都将根据以下情况收取费用。",
          },
          interpretation: {
            title: "口译服务",
            description:
              "当客户预订了口译服务的任何模式，在预付款支付或合同签署后至活动开始前72小时内的任何时间，如果因非Shaoyao Consulting的原因取消服务，客户将支付报价总额50%的违约金。如果在活动开始前 72 小时内取消，违约金将为报价总额的 100%。 \n\n同样，在支付定金或签订合同后的任何时间通知Shaoyao Consulting更改日期或时间，Shaoyao Consulting都将予以考虑，并可能根据口译员的可用性接受或拒绝。如果Shaoyao Consulting不接受更改，则将按照原预订的日期和时间提供服务，客户必须接受原日期或按照前款规定取消服务。 ",
          },
          translation: {
            title: "翻译服务",
            description:
              "当客户已接受任何形式的翻译服务，或任何其他包含交付书面交付品的服务，在预付款支付后或合同签署后至约定交付日期前 5 个工作日内的任何时间，如果因非Shaoyao Consulting的原因取消服务，客户应支付报价总额 50%的违约金。如果在约定交货日期前 5 个工作日内取消合同，违约金将为报价总额的 100%。 \n\n同样，在支付预付金或签订合同后，客户随时通知Shaoyao Consulting更改交付日期，Shaoyao Consulting将视翻译和/或顾问的可用性予以考虑并接受或拒绝。如果Shaoyao Consulting不接受变更，则将按原约定日期交付稿件，客户必须接受原日期或根据上述条款取消服务。 ",
          },
          training: {
            title: "培训服务",
            description:
              "当客户预订了任何形式的培训服务，包括课程、工作坊、会议、网络研讨会以及任何其他需要一个或多个讲师或讲师面对面或虚拟出席的服务时，在预付款或签订合同后至活动开始前 72 小时内的任何时间，如果因非Shaoyao Consulting的原因而取消，客户将支付报价总额 50%的违约金。如果在活动开始前 72 小时内取消，则违约金为报价总额的 100%。 \n\n在支付定金或签订合同后，客户可随时通知邵逸夫咨询公司更改日期或时间，邵逸夫咨询公司将根据演讲者和/或讲师的可用性考虑接受或拒绝更改。如果Shaoyao Consulting不接受更改，则将按照原定日期和时间提供服务，客户必须接受原定日期或按照前款规定取消服务。 ",
          },
          final: {
            title: "结束语",
            description:
              "如果由于Shaoyao Consulting的原因而取消本政策中提及的任何服务，Shaoyao Consulting将向客户全额退还预付款（如有），而不承担任何进一步的违约金，也不对客户承担任何进一步的责任。 \n\n在任何情况下，客户都不得亲自选择口译员、笔译员、讲师、顾问或其他专业人员，这是Shaoyao Consulting的专有权利。因此，在任何时候、任何情况下，客户接受的一项或多项服务的执行人的身份都不应被视为可归咎于Shaoyao Consulting的取消服务的有效理由，而应完全由客户负责取消该项或多项服务。 \n\n通过支付聘金和/或签署合同（如适用）的方式接受Shaoyao Consulting提供的任何服务，即表示您同意受本取消政策及其条款的约束。",
          },
        });
        break;
      case "Español":
        setCancellationText({
          cancellation: {
            title: "POLÍTICA DE CANCELACIONES",
            description:
              "Los servicios que Shaoyao Consulting brinda a sus clientes son ejecutados por un selecto grupo de profesionales cuya disponibilidad es limitada. Por dicho motivo, las fechas para contratación de nuestros servicios se reservan exclusivamente por medio del pago de un anticipo o mediante la firma de un contrato, según aplique el caso. Asimismo se les informa todos nuestros clientes que toda cancelación o cambio generará un cargo conforme a los siguientes escenarios.",
          },
          interpretation: {
            title: "SERVICIOS DE INTERPRETACIÓN",
            description:
              "Cuando el cliente haya reservado las fechas para un servicio de interpretación en cualquiera de sus modalidades, en caso de que se cancele el mismo por causas no imputables a Shaoyao Consulting en cualquier momento después de pagado el anticipo o firmado el contrato y hasta 72 horas antes del inicio del evento, el cliente pagará una penalización del 50% del monto total cotizado. En caso de que la cancelación suceda dentro de las 72 horas anteriores al inicio del evento, la penalización será del 100% del monto total cotizado.\n\nAsimismo, los cambios de fecha u horario que se notifiquen a Shaoyao Consulting en cualquier momento después de pagado el anticipo o firmado el contrato estarán sujetos a consideración y podrán ser aceptados o rechazados dependiendo de la disponibilidad de los intérpretes. En caso de que los cambios no sean aceptados por Shaoyao Consulting, el servicio se brindará en las fechas y horarios reservados originalmente, teniendo el cliente que aceptar las fechas originales o bien proceder con la cancelación del servicio conforme a las condiciones del párrafo anterior.",
          },
          translation: {
            title: "SERVICIOS DE TRADUCCIÓN",
            description:
              "Cuando el cliente haya aceptado un servicio de traducción en cualquiera de sus modalidades, o cualquier otro servicio que consista en el envío de entregables escritos, en caso de que se cancele el mismo por causas no imputables a Shaoyao Consulting en cualquier momento después de pagado el anticipo o firmado el contrato y hasta 5 días hábiles antes de la fecha de entrega acordada, el cliente pagará una penalización del 50% del monto total cotizado. En caso de que la cancelación suceda dentro de los 5 días hábiles anteriores a  la fecha de entrega acordada, la penalización será del 100% del monto total cotizado.\n\nAsimismo, los cambios de fecha de entrega que se notifiquen a Shaoyao Consulting en cualquier momento después de pagado el anticipo o firmado el contrato estarán sujetos a consideración y podrán ser aceptados o rechazados dependiendo de la disponibilidad de los traductores y/o consultores. En caso de que los cambios no sean aceptados por Shaoyao Consulting, los entregables se enviarán en la fecha acordada originalmente, teniendo el cliente que aceptar la fecha original o bien proceder con la cancelación del servicio conforme a las condiciones del párrafo anterior.",
          },
          training: {
            title: "SERVICIOS DE CAPACITACIÓN",
            description:
              "Cuando el cliente haya reservado las fechas para un servicio de capacitación en cualquiera de sus modalidades, incluyendo cursos, talleres, conferencias, webinarios, y cualquier otro servicio que requiera de la asistencia presencial o virtual de uno o varios ponentes o instructores, en caso de que se cancele el mismo por causas no imputables a Shaoyao Consulting en cualquier momento después de pagado el anticipo o firmado el contrato y hasta 72 horas antes del inicio del evento, el cliente pagará una penalización del 50% del monto total cotizado. En caso de que la cancelación suceda dentro de las 72 horas anteriores al inicio del evento, la penalización será del 100% del monto total cotizado.\n\nAsimismo, los cambios de fecha u horario que se notifiquen a Shaoyao Consulting en cualquier momento después de pagado el anticipo o firmado el contrato estarán sujetos a consideración y podrán ser aceptados o rechazados dependiendo de la disponibilidad de los ponentes y/o instructores. En caso de que los cambios no sean aceptados por Shaoyao Consulting, el servicio se brindará en las fechas y horarios reservados originalmente, teniendo el cliente que aceptar las fechas originales o bien proceder con la cancelación del servicio conforme a las condiciones del párrafo anterior.",
          },
          final: {
            title: "CONSIDERACIONES FINALES",
            description:
              "En caso cancelación de cualquiera de los servicios mencionados en esta política por causas imputables a Shaoyao Consulting, Shaoyao Consuting devolverá al cliente la totalidad del monto que le haya sido transferido por concepto de anticipo (si lo hubiese), no incurriendo en ninguna otra penalización y quedando libre de toda responsabilidad adicional para con el cliente.\n\nEn ningún caso el cliente podrá elegir personalmente a los intérpretes, traductores, instructores, consultores u otros profesionales que se asignen a su proyecto, siendo este el derecho exclusivo de Shaoyao Consulting. Por lo tanto, la identidad de la persona o personas que ejecuten el servicio o servicios aceptados por el cliente no podrá en ningún momento y bajo ninguna circunstancia considerarse una razón de cancelación válida imputable a Shaoyao Consulting, sino que recaerá exclusivamente dentro de la responsabilidad del cliente el cancelar un servicio o servicios por dicho motivo.\n\nAl aceptar cualquiera de los servicios brindados por Shaoyao Consulting por medio del pago de un anticipo y/o la firma de un contrato, según aplique el caso, usted acepta que está de acuerdo con lo expresado en esta política de cancelaciones y se atiene a sus condiciones.",
          },
        });
        break;
    }
  }, [language]);

  return (
    <PageComponent>
      <div className="flex justify-center text-charcoal bg-[#1E1E1E] bg-[linear-gradient(115deg,_rgba(35,_63,_87,_0.74)_17.27%,_rgba(0,_87,_73,_0.74)_82.07%)] py-2 shadow-lg whitespace-pre-line">
        <div className="md:w-4/5 p-6 md:p-10 md:px-28 bg-white">
          <h1 className="flex justify-center font-bold text-lg">
            {cancellationText.cancellation.title}
          </h1>
          <br />
          <p>{cancellationText.cancellation.description}</p>
          <br />
          <h1 className="font-bold">{cancellationText.interpretation.title}</h1>
          <br />
          <p>{cancellationText.interpretation.description}</p>
          <br />
          <h1 className="font-bold">{cancellationText.translation.title}</h1>
          <br />
          <p>{cancellationText.translation.description}</p>
          <br />
          <h1 className="font-bold">{cancellationText.training.title}</h1>
          <br />
          <p>{cancellationText.training.description}</p>
          <br />
          <h1 className="font-bold">{cancellationText.final.title}</h1>
          <br />
          <p>{cancellationText.final.description}</p>
          <br />
        </div>
      </div>
    </PageComponent>
  );
};

export default Cancellation;
